import React, { useState } from 'react';

const Home: React.FC = () => {
  const [showNotification, setShowNotification] = useState(false);
  const email = "linuskanestad@gmail.com";

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(email);

    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000); // Notification will disappear after 2 seconds
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-br from-[#0f172a] to-[#1e293b] px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <h1 className="mb-4 text-5xl font-bold tracking-tight text-white sm:text-6xl md:text-7xl">
          <span className="bg-gradient-to-r from-[#5a26d3] to-[#825afa] bg-clip-text font-['Orbitron'] text-transparent">
            aiphoros
          </span>
        </h1>
        <p className="mt-4 text-xl font-medium font-bold font-['Orbitron'] text-blue-400">
          Explore the future of tech.
        </p>
        <div className="mt-8">
          <button
            onClick={handleButtonClick}
            className="inline-flex items-center rounded-md bg-gradient-to-r from-[#5a26d3] to-[#8e26d3] px-4 py-2 text-lg font-['Orbitron'] font-medium text-white shadow-sm transition-colors hover:from-[#5a26d3] hover:to-[#c026d3] focus:outline-none focus:ring-2 focus:ring-[#c026d3] focus:ring-offset-2"
          >
            <span className="text-lg font-bold font-['Orbitron'] leading-relaxed text-gray-200">contact information</span>
          </button>
        </div>
        <div className="mt-4 h-10 flex items-center justify-center">
          <div
            className={`transition-opacity duration-500 ease-in-out ${
              showNotification ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <span className="mt-4 text-lg font-small font-['Orbitron'] leading-relaxed text-blue-400">Contact information copied.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;