import React from 'react';
import logo from './logo.png';
import './App.css';

import Welcome from './components/Welcome'

const App: React.FC = () => {
  return (
    <div>
      <Welcome />
    </div>
  );
};

export default App;
